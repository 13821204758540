<template>
  <b-card no-body class="mb-0">
    <input id="attribute-import" ref="attributeImport" hidden type="file" @change="uploadAttribute">
    <app-data-table
      :busy="attributesLoading"
      :top-table="{
        showAdd: $can('OPERATOR_ATTRIBUTE_ADD'),
        showImport: $can('OPERATOR_ATTRIBUTE_ADD'),
        showExport: $can('OPERATOR_ATTRIBUTE_ADD'),
        showSearch:$can('OPERATOR_ATTRIBUTE_VIEW'),
        searchText: params.category,
        searchFilterOptions,
      }"
      :actions-table="{
        showEdit: $can('OPERATOR_ATTRIBUTE_EDIT'),
        showDelete: $can('OPERATOR_ATTRIBUTE_DELETE'),
      }"
      :bottom-table="{ totalCount: totalAttributs }"
      :fields="tableFields"
      :items="attributes"
      :page="params.page"
      :per-page="params.numberOfItemsPerPage"
      :sort-by.sync="sort.sortBy"
      :sort-desc.sync="sort.sortDesc"
      manual-pagination
      @table-add="$router.push({ name: 'attribute-add' })"
      @table-import="selectAttribute"
      @table-export="exportAttribute"
      @table-search-text="params.category = $event"
      @edit="$router.push({ name: 'attribute-edit', params: { attribute_id: $event.item.id } })"
      @delete="deleteAttibute($event.item)"
      @table-select-per-page="params.numberOfItemsPerPage = $event"
      @pagination="params.page = $event"
    >
      <!-- Column: Category IDS -->
      <template #cell(id)="data">
        <ul>
          <li v-for="category in data.item.categories" :key="category.id">{{ category.name|trans }} (#{{ category.id }})</li>
        </ul>
      </template>

      <!-- Column: Attribut -->
      <template #cell(name)="data">
        <b-media vertical-align="center" :to="{ name: 'attribute-edit', params: { attribute_id: data.item.id } }">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.name | trans }}
          </div>
        </b-media>
      </template>

      <!-- Column: Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <font-awesome-icon :icon="resolveRoleIcon(data.item.role)" class="mr-50" :class="`text-${resolveRoleVariant(data.item.role)}`" />
          <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
        </div>
      </template>

      <!-- Column: Filterable -->
      <template #cell(filterable)="data">
        <b-form-checkbox disabled :checked="data.item.filterable" name="check-button" switch inline />
      </template>

      <!-- Column: Required -->
      <template #cell(required)="data">
        <b-form-checkbox disabled :checked="data.item.required" name="check-button" switch inline />
      </template>

      <!-- Column: Type -->
      <template #cell(type)="data">
        <b-badge>
          {{ data.item.type }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data" style="min-width: 10rem">
        <!--EDIT-->
        <b-button
          v-ripple.400
          :disabled="!$can('OPERATOR_ATTRIBUTE_EDIT')"
          variant="flat-primary"
          class="btn-icon mr-50"
          size="sm"
          :to="{ name: 'attribute-edit', params: { attribute_id: data.item.id } }"
        >
          <feather-icon v-b-tooltip.hover.bottom="$t('action.edit')" icon="Edit2Icon" size="20" />
        </b-button>

        <!--DELETE-->
        <b-button
          v-ripple.400
          :disabled="!$can('OPERATOR_ATTRIBUTE_DELETE')"
          variant="flat-danger"
          class="btn-icon mr-50"
          size="sm"
          @click="deleteAttibute(data.item)"
        >
          <feather-icon v-b-tooltip.hover.bottom="$t('action.delete')" icon="TrashIcon" size="18" />
        </b-button>
      </template>
    </app-data-table>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import UIHelper from '@/helpers/ui'

import AppDataTable from '@/components/AppDataTable.vue'
import { deleteAttributeRequest, fetchAttributesRequest, uploadAttributeCSV, exportAttributeCSV } from '@/request/globalApi/requests/attributeRequests'

export default {
  name: 'AttributeList',

  components: {
    AppDataTable,
  },

  mixins: [UIHelper],
  data() {
    return {
      searchFilterOptions: [
        { text: this.$t('attribute.filter.category'), value: 'category' },
      ],
      attributes: [],
      attributesLoading: true,
      params: {
        numberOfItemsPerPage: 10,
        page: 1,
        category: null,
        filterable: null,
      },
      sort: {
        sortBy: null,
        sortDesc: null,
      },
      totalAttributs: 0,
    }
  },
  computed: {
    ...mapState('appConfig', ['lang']),
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        // SEARCH
        category: this.params.category,
        filterable: this.params.filterable,
      }
    },
    tableFields() {
      return [
        { key: 'name', label: this.$t('attribute.name'), sortable: true },
        { key: 'id', label: this.$t('attribute.attached_categories') },
        { key: 'filterable', label: this.$t('attribute.filterable') },
        { key: 'required', label: this.$t('attribute.required'), sortable: true },
        { key: 'type', label: this.$t('attribute.type'), sortable: true },
      ]
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.fetchAttributes()
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchAttributes()
  },
  methods: {
    fetchAttributes() {
      this.attributesLoading = true
      fetchAttributesRequest(this.params).then(response => {
        const { attributes, totalItems } = response.data

        this.attributes = attributes
        this.totalAttributs = totalItems
      }).finally(() => {
        this.attributesLoading = false
      })
    },
    deleteAttibute(attribute) {
      deleteAttributeRequest(attribute.id, attribute.name[this.lang]).then(() => {
        this.fetchAttributes()
      })
    },
    selectAttribute() {
      document.getElementById('attribute-import').click()
    },
    exportAttribute() {
      exportAttributeCSV()
    },
    uploadAttribute(event) {
      if (event.target.files.length) {
        const data = new FormData()
        data.append('csv_file', event.target.files[0])

        uploadAttributeCSV(data).then(response => {
          const { errors } = response.data
          if (errors) {
            errors.forEach(element => {
              this.toaster(element, 'warning', 'AlertCircleIcon')
            });
          }

          this.fetchAttributes()
        })
      }
    },
  },
}
</script>
